
import Vue from "vue";
import rules from "@/services/helpers/validation-rules";

export default Vue.extend({
  name: "Create",

  data: () => ({
    rules,
    model: {
      accept_ata: false as boolean
    },
    errorMessages: {} as { [value: string]: Array<string> }
  }),

  methods: {
    async submit(): Promise<void> {
      try {
        if ((this.$refs.form as Vue).validate()) {
          await this.$API.countries().create(this.model);
          await this.$router.push("/countries");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_create")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;

          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  }
});
